export const SQRT_3 = Math.sqrt(3.0);

// Basic hexagon math: If a side is length 2, then the long radius is 2, and
// short radius is sqrt(3). Here are the polygon points starting with the point
// at top, going clockwise, and center at (0, 0), with side length 1:
export const hexPolygon: Point[] = [
  { x: 0, y: -2 / 2 },
  { x: SQRT_3 / 2, y: -1 / 2 },
  { x: SQRT_3 / 2, y: 1 / 2 },
  { x: 0, y: 2 / 2 },
  { x: -SQRT_3 / 2, y: 1 / 2 },
  { x: -SQRT_3 / 2, y: -1 / 2 },
  { x: 0, y: -2 / 2 },
];

// The same polygon as a string with unit width and height.
export const svgHexPoints =
  ` ${0} ${-2 / 4}` +
  ` ${SQRT_3 / 4} ${-1 / 4}` +
  ` ${SQRT_3 / 4} ${1 / 4}` +
  ` ${0} ${2 / 4}` +
  ` ${-SQRT_3 / 4} ${1 / 4}` +
  ` ${-SQRT_3 / 4} ${-1 / 4}` +
  ` ${0} ${-2 / 4}`;

export const svgHexPoints2 =
  ` ${0} ${-2 / 4}` +
  ` ${(1 * SQRT_3) / 4} ${-1 / 4}` +
  ` ${(2 * SQRT_3) / 4} ${-2 / 4}` +
  ` ${(3 * SQRT_3) / 4} ${-1 / 4}` +
  ` ${(3 * SQRT_3) / 4} ${1 / 4}` +
  ` ${(2 * SQRT_3) / 4} ${2 / 4}` +
  ` ${(1 * SQRT_3) / 4} ${1 / 4}` +
  ` ${0} ${2 / 4}` +
  ` ${-SQRT_3 / 4} ${1 / 4}` +
  ` ${-SQRT_3 / 4} ${-1 / 4}` +
  ` ${0} ${-2 / 4}`;

export const svgHexPoints3 =
  ` ${0} ${-2 / 4}` +
  ` ${0} ${-4 / 4}` +
  ` ${(1 * SQRT_3) / 4} ${-5 / 4}` +
  ` ${(2 * SQRT_3) / 4} ${-4 / 4}` +
  ` ${(2 * SQRT_3) / 4} ${-2 / 4}` +
  ` ${(3 * SQRT_3) / 4} ${-1 / 4}` +
  ` ${(3 * SQRT_3) / 4} ${1 / 4}` +
  ` ${(2 * SQRT_3) / 4} ${2 / 4}` +
  ` ${(1 * SQRT_3) / 4} ${1 / 4}` +
  ` ${0} ${2 / 4}` +
  ` ${-SQRT_3 / 4} ${1 / 4}` +
  ` ${-SQRT_3 / 4} ${-1 / 4}` +
  ` ${0} ${-2 / 4}`;

export const svgShapePoints: string[] = [
  svgHexPoints,
  svgHexPoints,
  svgHexPoints2,
  svgHexPoints3,
];

export const svgHexMaskPointy =
  ` ${0 + 0.5} ${-2 / 4 + 0.5}` +
  ` ${SQRT_3 / 4 + 0.5} ${-1 / 4 + 0.5}` +
  ` ${SQRT_3 / 4 + 0.5} ${1 / 4 + 0.5}` +
  ` ${0 + 0.5} ${2 / 4 + 0.5}` +
  ` ${-SQRT_3 / 4 + 0.5} ${1 / 4 + 0.5}` +
  ` ${-SQRT_3 / 4 + 0.5} ${-1 / 4 + 0.5}` +
  ` ${0 + 0.5} ${-2 / 4 + 0.5}`;

export const svgHexMaskFlat =
  ` ${-2 / 4 + 0.5} ${0 + 0.5}` +
  ` ${-1 / 4 + 0.5} ${SQRT_3 / 4 + 0.5}` +
  ` ${1 / 4 + 0.5} ${SQRT_3 / 4 + 0.5}` +
  ` ${2 / 4 + 0.5} ${0 + 0.5}` +
  ` ${1 / 4 + 0.5} ${-SQRT_3 / 4 + 0.5}` +
  ` ${-1 / 4 + 0.5} ${-SQRT_3 / 4 + 0.5}` +
  ` ${-2 / 4 + 0.5} ${0 + 0.5}`;

export interface Point {
  x: number;
  y: number;
}

export interface Hex {
  q: number;
  r: number;
}

export interface Oddr {
  col: number;
  row: number;
}

export interface Oddq {
  col: number;
  row: number;
}

export interface Cube {
  x: number;
  y: number;
  z: number;
}

export function Point(x: number, y: number) {
  return new PointImpl(x, y);
}

export function Oddr(col: number, row: number) {
  return new OddrImpl(col, row);
}

export function Oddq(col: number, row: number) {
  return new OddqImpl(col, row);
}

export function Hex(q: number, r: number) {
  return new HexImpl(q, r);
}

export function Cube(x: number, y: number, z: number) {
  return new CubeImpl(x, y, z);
}

export function HexToOddr(hex: Hex): Oddr {
  return cube_to_oddr(axial_to_cube(hex));
}

export function OddrToHex(oddr: Oddr): Hex {
  return cube_to_axial(oddr_to_cube(oddr));
}

export function HexToOddq(hex: Hex): Oddr {
  return cube_to_oddq(axial_to_cube(hex));
}

export function OddqToHex(oddr: Oddq): Hex {
  return cube_to_axial(oddq_to_cube(oddr));
}

function cube_to_axial(cube: Cube): Hex {
  var q = cube.x;
  var r = cube.z;
  return Hex(q, r);
}

function axial_to_cube(hex: Hex): Cube {
  var x = hex.q;
  var z = hex.r;
  var y = -x - z;
  return Cube(x, y, z);
}

/* oddr coords. pointy tops. (col,row)
 *  0,0     1,0     2,0
 *      0,1     1,1     2,1
 *  0,2     1,2     2,2
 */
function cube_to_oddr(cube: Cube): Oddr {
  var col = cube.x + (cube.z - (cube.z & 1)) / 2;
  var row = cube.z;
  return Oddr(col, row);
}

function oddr_to_cube(oddr: Oddr): Cube {
  var x = oddr.col - (oddr.row - (oddr.row & 1)) / 2;
  var z = oddr.row;
  var y = -x - z;
  return Cube(x, y, z);
}

function cube_to_oddq(cube: Cube): Oddq {
  const col = cube.x;
  const row = cube.z + (cube.x - (cube.x & 1)) / 2;
  return Oddq(col, row);
}

function oddq_to_cube(oddq: Oddq) {
  const x = oddq.col;
  const z = oddq.row - (oddq.col - (oddq.col & 1)) / 2;
  const y = -x - z;
  return Cube(x, y, z);
}

function cube_round(cube: Cube): Cube {
  var rx = Math.round(cube.x);
  var ry = Math.round(cube.y);
  var rz = Math.round(cube.z);
  var x_diff = Math.abs(rx - cube.x);
  var y_diff = Math.abs(ry - cube.y);
  var z_diff = Math.abs(rz - cube.z);
  if (x_diff > y_diff && x_diff > z_diff) {
    rx = -ry - rz;
  } else if (y_diff > z_diff) {
    ry = -rx - rz;
  } else {
    rz = -rx - ry;
  }
  return Cube(rx, ry, rz);
}

function hex_round(hex: Hex): Hex {
  return cube_to_axial(cube_round(axial_to_cube(hex)));
}

export function rotate_hex(hex_pos: Hex, rot60: number): Hex {
  var pos2 = -hex_pos.q - hex_pos.r;
  switch (((rot60 % 6) + 6) % 6) {
    case 0:
      return hex_pos;

    case 1:
      return Hex(-hex_pos.r, -pos2);

    case 2:
      return Hex(pos2, hex_pos.q);

    case 3:
      return Hex(-hex_pos.q, -hex_pos.r);

    case 4:
      return Hex(hex_pos.r, pos2);

    case 5:
      return Hex(-pos2, -hex_pos.q);
  }
  return hex_pos;
}

export function sub_hex(hex1: Hex, hex2: Hex): Hex {
  return Hex(hex1.q - hex2.q, hex1.r - hex2.r);
}

export function add_hex(hex1: Hex, hex2: Hex): Hex {
  return Hex(hex1.q + hex2.q, hex1.r + hex2.r);
}

export function equal_hex(hex1: Hex, hex2: Hex): boolean {
  return hex1.q === hex2.q && hex1.r === hex2.r;
}

export function compare_hex(hex1: Hex, hex2: Hex): number {
  if (hex1.r != hex2.r) return hex1.r - hex2.r;
  return hex1.q - hex2.q;
}

export function in_range(a: Hex, b: Hex, range: number) {
  return hex_distance(a, b) <= range;
}

export function hex_distance(a: Hex, b: Hex) {
  return cube_distance(axial_to_cube(a), axial_to_cube(b));
}

function cube_distance(a: Cube, b: Cube) {
  return Math.max(Math.abs(a.x - b.x), Math.abs(a.y - b.y), Math.abs(a.z - b.z));
}

export function pointy_hex_to_pixel(hex: Hex, size: number): Point {
  var x = size * (SQRT_3 * hex.q + (SQRT_3 / 2) * hex.r);
  var y = size * ((3.0 / 2) * hex.r);
  return Point(x, y);
}

export function flat_hex_to_pixel(hex: Hex, size: number): Point {
  var x = size * ((3.0 / 2) * hex.q);
  var y = size * ((SQRT_3 / 2) * hex.q + SQRT_3 * hex.r);
  return Point(x, y);
}

export function pixel_to_pointy_hex(point: Point, size: number): Hex {
  var q = ((point.x * SQRT_3) / 3 - (point.y * 1.0) / 3) / size;
  var r = (point.y * 2.0) / 3 / size;
  return hex_round(Hex(q, r));
}

export function pixel_to_flat_hex(point: Point, size: number): Hex {
  var q = (point.x * 2) / 3 / size;
  var r = ((point.x * -1) / 3 + (point.y * SQRT_3) / 3) / size;
  return hex_round(Hex(q, r));
}

export function in_point(point1: Point, point2: Point, radius: number): boolean {
  return Math.abs(point1.x - point2.x) < radius && Math.abs(point1.y - point2.y) < radius;
}

export function sub_point(point1: Point, point2: Point): Point {
  return Point(point1.x - point2.x, point1.y - point2.y);
}

export function add_point(point1: Point, point2: Point): Point {
  return Point(point1.x + point2.x, point1.y + point2.y);
}

export function scale_point(point1: Point, scale: number): Point {
  return Point(point1.x * scale, point1.y * scale);
}

export function equal_point(point1: Point, point2: Point): boolean {
  return point1.x === point2.x && point1.y === point2.y;
}

export function centroid_point(points: Point[]) {
  return scale_point(
    points.reduce((pt, sum) => add_point(pt, sum), Point(0, 0)),
    1 / points.length
  );
}

class PointImpl implements Point {
  public x: number;
  public y: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

class HexImpl implements Hex {
  public q: number;
  public r: number;
  constructor(q: number, r: number) {
    this.q = q;
    this.r = r;
  }
}

class OddrImpl implements Oddr {
  public col: number;
  public row: number;
  constructor(col: number, row: number) {
    this.col = col;
    this.row = row;
  }
}

class OddqImpl implements Oddq {
  public col: number;
  public row: number;
  constructor(col: number, row: number) {
    this.col = col;
    this.row = row;
  }
}

class CubeImpl implements Cube {
  public x: number;
  public y: number;
  public z: number;
  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }
}
