// client_path is where to serve static files from
// server_url is for sockets and openid authentication
export const server_url = 'https://dev.smigiel.us';
export const server_path = '/srv/dev.smigiel.us';
export const server_port = 3001;
export const client_url = 'https://dev.smigiel.us';
export const client_url_v2 = 'https://dev.smigiel.us/v2';
export const table_application = 'dev_application';
export const table_anonymous = 'dev_anonymous';
export const table_full_campaign = 'dev_full_campaign';
export const table_transaction = 'dev_transaction';
export const table_vars = 'dev_vars';
export const table_client = 'dev_client';
export const table_client_campaign = 'dev_client_campaign';
export const table_federated_credential = 'dev_federated_credential';
export const table_feedback = 'dev_feedback';
export const table_info = 'dev_new_info';
export const table_user_info = 'dev_user_info';
export const table_map_setup = 'dev_new_map_setup';
export const table_scenario_pages = 'dev_scenario_pages';
export const prefix = 'dev_';
