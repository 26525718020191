import { tick } from 'svelte';
import { prefix } from 'common/config';

export type PopupPosition = 'before' | 'after' | 'center' | 'align-end' | 'align-start';

export interface ScrollPos {
  left: number;
  top: number;
}

export function save_scroll(key: string, element: HTMLElement) {
  if (!key) return;
  const scroll =
    element && (element.scrollLeft !== 0 || element.scrollTop !== 0)
      ? ({
          left: element.scrollLeft,
          top: element.scrollTop,
        } as ScrollPos)
      : null;
  local_set(key + '_scroll', scroll);
}

export function get_scroll(key: string) {
  const scroll = local_get(key + '_scroll', null);
  if (scroll && scroll.top === 0 && scroll.left === 0) return null;
  return scroll;
}

export async function set_scroll(key: string, element: HTMLElement, pos?: ScrollPos) {
  const scroll: ScrollPos = pos ? pos : local_get(key + '_scroll', null);
  if (scroll && element) {
    await tick();
    element.scrollTo(scroll);
  }
}

export function local_get<T>(key: string, default_value: T): T {
  const value = localStorage.getItem('v2_' + prefix + key);
  if (value === null || value === undefined || value === 'undefined')
    return default_value;
  try {
    return JSON.parse(value);
  } catch (e) {
    console.log('local_get', 'v2_' + prefix + key, typeof value, value, e);
    return default_value;
  }
}

export function local_set<T>(
  key: string,
  value: T,
  replacer_fn?: (k: any, v: any) => any
) {
  localStorage.setItem('v2_' + prefix + key, JSON.stringify(value, replacer_fn));
}

export function global_get(key: string, def: string): string {
  return localStorage.getItem(prefix + key) || def;
}

export function global_set(key: string, value: string) {
  localStorage.setItem(prefix + key, value);
}

export function log(data: any) {
  if (process.env.NODE_ENV !== 'production') {
    const now = new Date().toISOString();
    console.log(now);
    console.log(JSON.stringify(data));
  }
}

export function mouse_to_dom(event: MouseEvent): DOMPoint {
  const element: any = event.currentTarget;
  let pt = new DOMPoint(event.clientX, event.clientY);
  return pt.matrixTransform(element.getScreenCTM().inverse());
}

export function mouse_to_svg(event: MouseEvent): SVGPoint {
  const element: any = event.currentTarget;
  const pt = element.createSVGPoint();
  pt.x = event.clientX;
  pt.y = event.clientY;
  return pt.matrixTransform(element.getScreenCTM().inverse());
}

export function touch_to_svg(event: TouchEvent) {
  const element: any = event.currentTarget;
  const pt = element.createSVGPoint();
  pt.x = event.touches[0].clientX;
  pt.y = event.touches[0].clientY;
  return pt.matrixTransform(element.getScreenCTM().inverse());
}
