import type { RouteDetail } from 'svelte-spa-router';
import type { Writable } from 'svelte/store';
import { writable } from 'svelte/store';
import type { AccountDetail } from 'common/account';
import { server_url } from 'common/config';

export const account: Writable<AccountDetail> = writable({ tags: [], uuid: '' });

export async function load_account(detail: RouteDetail) {
  const search = new URLSearchParams(detail.querystring);
  const uuid = search.get('application');
  if (!uuid) return false;
  const response = await fetch(server_url + '/v2/account?application=' + uuid);
  const data = await response.json();
  if (!data?.tags) return false;
  account.update(() => data);
  return true;
}
