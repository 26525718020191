import * as stores from '$lib/stores';
import type { RouteDetail } from 'svelte-spa-router';
import { pop, push, replace } from 'svelte-spa-router';
import type { Writable } from 'svelte/store';
import { client_url_v2 } from 'common/config';
import type { User } from 'common/client';
import type {
  ElementState,
  FigureGroup,
  FigureState,
  PlayingState,
} from 'common/gloomhaven';
import {
  PlayState,
  get_figure_group,
  is_group_completed,
  is_setting_initiative,
} from 'common/gloomhaven';
import type { Hex, Point } from 'common/hex';
import type { InfoKind, InfoRecord, ModifierKind } from 'common/info';
import { is_character } from 'common/info';
import { JournalKind } from 'common/journal';
import type { MapItem } from 'common/map';
import type { RenderPageData } from 'common/render';
import type { Action, Ability } from 'common/stats';
import type { HashMap } from 'common/utility';
import { string_to_hex, base64ToBytes } from '../common/utility.js';
import type { Network } from 'v2/network';
import { clear_selected_hex, play_draw_audio } from 'v2/settings';
import { global_get, global_set, local_get, log } from 'v2/util';

interface FsRouteDetail {
  route: string;
  route_params: any;
  campaign_uuid: string;
  anon_uuid: string;
}

let current_route: FsRouteDetail = null;
let prefetch_route: string = null;
let prefetch_replace: boolean = false;
let transaction_id = 0;
let user: User = { uuid: '', name: '' };
let network: Network = null;
let prev_ack = 0;
let prev_trip = 0;

let page_cache: HashMap<boolean> = {};

function reset_cache() {
  // This invalidate will cause a reload of the current page, but we rely on
  // the page cache to prevent additional network calls.
  page_cache = {};
}

export function set_network(_network: Network) {
  // Network is expected to always exist and manage connections internally.
  // "Always" as in as long as the creating +layout is still mounted.
  if (network) network.close('set_network');
  network = _network;
}

export function is_client_id(id: string) {
  return !id || id === (user.name || user.uuid || network?.socket_id());
}

export function get_network() {
  return network;
}

export function set_current_route(
  route: string,
  route_params: any,
  campaign_uuid: string,
  anon_uuid: string
) {
  current_route = { route, route_params, campaign_uuid, anon_uuid };
}

export function get_current_route() {
  return current_route;
}

export function get_current_campaign_uuid() {
  return current_route?.campaign_uuid || current_route?.anon_uuid;
}

export function get_campaign_uuid() {
  return current_route?.campaign_uuid;
}

export function get_anon_uuid() {
  return current_route?.anon_uuid;
}

export function get_read_only_url(uuid: string): string {
  const base = client_url_v2 + '/#/c/play/tracker';
  return current_route?.campaign_uuid
    ? base + '/?campaign=' + uuid
    : current_route?.anon_uuid
    ? base + '/?anon=' + uuid
    : null;
}

export function get_export_url(file_path: string, name: string): string {
  const base = client_url_v2 + '/export/' + file_path + '/' + string_to_hex(name);
  return current_route?.campaign_uuid
    ? base + '/?campaign=' + current_route.campaign_uuid
    : current_route?.anon_uuid
    ? base + '/?anon=' + current_route.anon_uuid
    : null;
}

const uuid_regex = new RegExp('^[-0-9a-f]+$');
const base64_regex = new RegExp('^b64.[0-9a-zA-Z+/]+=*$');

export function set_user_uuid(uuid: string) {
  user.uuid = uuid;
  global_set('connection_uuid', uuid);
}

export function set_user_name(name: string) {
  user.name = name;
  global_set('connection_name', name);
}

export function get_user(): User {
  const uuid = global_get('connection_uuid', '');
  const name = global_get('connection_name', '');
  if (uuid && !name && base64_regex.test(uuid)) {
    set_user_uuid(crypto.randomUUID());
    set_user_name(base64ToBytes(uuid.slice(4)));
    return user;
  }
  if (uuid && uuid_regex.test(uuid)) {
    user.uuid = uuid;
    user.name = name;
    return user;
  }
  const new_uuid = crypto.randomUUID();
  global_set('connection_uuid', new_uuid);
  user.uuid = new_uuid;
  return user;
}

export function get_user_uuid() {
  return get_user().uuid;
}

export function get_user_name() {
  return get_user().name;
}

export function cback() {
  // I added this to detect cases when the history is empty but could goto
  // '/' instead. But it doesn't work because sveltekit manipulates the
  // history.
  pop();
}

export function cerror(route: string, message: any) {
  console.log('cerror', route, message);
  if (current_route && current_route.campaign_uuid) {
    const query = new URLSearchParams({
      campaign: current_route.campaign_uuid,
      ...message,
    });
    window.location.replace('/#' + route + '/?' + query.toString());
    return;
  }
  if (current_route && current_route.anon_uuid) {
    const query = new URLSearchParams({ anon: current_route.anon_uuid, ...message });
    window.location.replace('/#' + route + '/?' + query.toString());
    return;
  }
  prefetch_route = null;
  log('no uuid: redirect');
  window.location.replace('/');
}

export function ctoggle() {
  if (!current_route?.route) return;
  if (current_route.route.startsWith('/c/play')) {
    cgoto('/c/sandbox');
  } else {
    cgoto('/c/play/' + local_get('play-screen', 'tracker'));
  }
}

export function cload(route: string) {
  if (current_route && current_route.campaign_uuid) {
    window.location.assign('/#' + route + '/?campaign=' + current_route.campaign_uuid);
    return;
  }
  if (current_route && current_route.anon_uuid) {
    window.location.assign('/#' + route + '/?anon=' + current_route.anon_uuid);
    return;
  }
  log('no uuid: redirect');
  push('/');
}

export function cgoto(route: string, _replace?: boolean, prefetch?: boolean) {
  if (prefetch) {
    prefetch_route = route;
    prefetch_replace = _replace;
    return;
  }
  if (current_route && current_route.campaign_uuid) {
    const uuid = current_route.campaign_uuid;
    const url = route + '/?campaign=' + uuid;
    if (_replace) replace(url);
    else push(url);
    return;
  }
  if (current_route && current_route.anon_uuid) {
    const uuid = current_route.anon_uuid;
    const url = route + '/?anon=' + uuid;
    if (_replace) replace(url);
    else push(url);
    return;
  }
  prefetch_route = null;
  log('no uuid: redirect');
  push('/');
}

export function update_page(page: RenderPageData, context: string, elapsed?: number) {
  // This tracks transaction_id to clear or update only the stores that need to
  // be changed.
  if (page.error) {
    console.log('page.error:', page.error);
    log(page.error.toString());
    stores.set_alert(page.error.toString().substring(0, 72));
    return;
  }
  if (page.help_page && page.transaction_id === undefined) {
    // User feedback updates do not always have the normal campaign data.
    stores.help_page.update(() => page.help_page);
    return;
  }
  if (page.transaction_id === undefined) {
    console.log('update_page: no transaction_id', page);
    return;
  }
  if (!current_route || (!current_route.campaign_uuid && !current_route.anon_uuid)) {
    console.log('update_page: no current_route', page);
    return;
  }
  const changed = page.transaction_id !== transaction_id;
  if (changed) reset_cache();
  page_cache[page.route_id] = true;
  log({ update_page: page.transaction_id, prev: transaction_id, context });
  if (changed)
    stores.set_alert(
      (transaction_id ? 'Update ' : 'Load ') + (elapsed ? ` ${elapsed}ms` : '')
    );
  transaction_id = page.transaction_id;
  if (page.server_event === 'gh:s:advance_state')
    stores.auto_center_id.update(() => transaction_id);
  else if (
    page.server_event === 'gh:s:set_initiative' &&
    page.play_page?.play_state === PlayState.DETERMINE_INITIATIVE &&
    page.campaign?.ro_uuid
  )
    play_draw_audio(page.campaign.ro_uuid.substr(8));
  stores.transaction_id.update(() => transaction_id);
  stores.campaign.update(() => page.campaign);
  update_store(changed, stores.characters_layout, page.characters_layout);
  update_store(changed, stores.play_page, page.play_page);
  update_store(changed, stores.play_logs_page, page.play_logs_page);
  update_store(changed, stores.sandbox_page, page.sandbox_page);
  update_store(changed, stores.scenario_page, page.scenario_page);
  update_store(changed, stores.solo_scenario_page, page.solo_scenario_page);
  update_store(changed, stores.prosperity_page, page.prosperity_page);
  update_store(changed, stores.global_achievements, page.global_achievements);
  update_store(changed, stores.party_achievements, page.party_achievements);
  update_store(changed, stores.reputation, page.reputation);
  update_store(changed, stores.special_condition_details, page.special_condition_details);
  update_store(changed, stores.party_goals_page, page.party_goals_page);
  update_store(changed, stores.sanctuary_page, page.sanctuary_page);
  update_store(changed, stores.town_records_page, page.town_records_page);
  update_store(changed, stores.characters_page, page.characters_page);
  update_store(changed, stores.character_ability_page, page.character_ability_page);
  update_store(changed, stores.retired_page, page.retired_page);
  update_store(changed, stores.new_character_page, page.new_character_page);
  update_store(changed, stores.lineage_page, page.lineage_page);
  update_store(changed, stores.shop_items_page, page.shop_items_page);
  update_store(changed, stores.unlock_items_page, page.unlock_items_page);
  update_store(changed, stores.unlock_quests_page, page.unlock_quests_page);
  update_store(changed, stores.unlock_goals_page, page.unlock_goals_page);
  update_store(changed, stores.journal_page, page.journal_page);
  update_store(changed, stores.random_scenarios_page, page.random_scenarios_page);
  update_store(changed, stores.random_items_page, page.random_items_page);
  update_store(changed, stores.battle_goals_page, page.battle_goals_page);
  update_store(changed, stores.challenges_page, page.challenges_page);
  update_store(changed, stores.pets_page, page.pets_page);
  update_store(changed, stores.favors_page, page.favors_page);
  update_store(changed, stores.world_map_page, page.world_map_page);
  update_store(changed, stores.difficulty_page, page.difficulty_page);
  update_store(changed, stores.attack_modifiers_page, page.attack_modifiers_page);
  update_store(changed, stores.choose_scenario_page, page.choose_scenario_page);
  update_store(changed, stores.treasure_index_page, page.treasure_index_page);
  update_store(changed, stores.add_monsters_page, page.add_monsters_page);
  update_store(changed, stores.end_scenario_page, page.end_scenario_page);
  update_store(changed, stores.unlock_classes_page, page.unlock_classes_page);
  update_store(changed, stores.settings_page, page.settings_page);
  update_store(changed, stores.help_page, page.help_page);
  update_store(changed, stores.books, page.books);
  update_store(changed, stores.scenario_pages_page, page.scenario_pages_page);
  update_store(changed, stores.section_page, page.section_page);
  update_store(changed, stores.edit_page_sections_page, page.edit_page_sections_page);
  update_store(changed, stores.edit_scenario_page, page.edit_scenario_page);
  update_store(changed, stores.edit_info_page, page.edit_info_page);
  update_store(changed, stores.browse_monster, page.browse_monster);
  update_store(changed, stores.browse_boss, page.browse_boss);
  update_store(changed, stores.browse_simple, page.browse_simple);
  update_store(changed, stores.browse_scenario, page.browse_scenario);
  update_store(changed, stores.browse_ability_card, page.browse_ability_card);
  update_store(changed, stores.campaign_sheet_page, page.campaign_sheet_page);
  update_store(changed, stores.buildings_page, page.buildings_page);
  update_store(changed, stores.loot_cards_page, page.loot_cards_page);
  update_store(changed, stores.unlock_buildings_page, page.unlock_buildings_page);
  // The following depend on route parameters so update when they exist.
  if (page.scenario_detail_page)
    stores.scenario_detail_page.update(() => page.scenario_detail_page);
  if (page.character_page) stores.character_page.update(() => page.character_page);
  if (page.enhance_page) stores.enhance_page.update(() => page.enhance_page);
  if (page.event_page) stores.event_page.update(() => page.event_page);
  if (page.event_deck_page) stores.event_deck_page.update(() => page.event_deck_page);
  if (prefetch_route) cgoto(prefetch_route, prefetch_replace);
}

function update_store<T>(changed: boolean, store: Writable<T>, data: T) {
  // Only update when the page data is provided. We keep old data to avoid
  // causing a page to rerender with null and rely on invalidate and resetting
  // the page_cache to make sure it is up to date.
  if (data != null) store.update(() => data);
}

export function receive_update(data: any) {
  // Some other client made a change and told this client to update.
  if (data.force || data?.transaction_id !== transaction_id) {
    log({
      receive_update: data?.transaction_id,
      prev: transaction_id,
      invalidate: true,
    });
    reset_cache();
    if (current_route)
      page_update(
        current_route.route,
        current_route.route_params,
        current_route.campaign_uuid,
        current_route.anon_uuid
      );
  }
}

export async function page_reload(no_cache?: boolean) {
  // Reload the data when we are not sure if it has changed or not.
  // So don't check or reset the cache.
  if (current_route)
    page_update(
      current_route.route,
      current_route.route_params,
      current_route.campaign_uuid,
      current_route.anon_uuid,
      no_cache
    );
}

// This is called by svelte-spa-router and should block until the page data is
// loaded. It always return true so routes/Connect.svelte does not show a black
// page and properly handles any incoming messages.
export async function page_load(detail: RouteDetail) {
  const search = new URLSearchParams(detail.querystring);
  const campaign_uuid = search.get('campaign');
  const anon_uuid = search.get('anon');
  await page_update(
    '/c' + (detail.route as string),
    detail.params,
    campaign_uuid,
    anon_uuid
  );
  return true;
}

function can_use_cache(route: string, params: any) {
  if (['/c/pages/book/:book_id/:page', '/c/play/:nav'].includes(route)) return true;
  return !params || Object.values(params).length === 0;
}

async function page_update(
  route: string,
  route_params: any,
  campaign_uuid: string,
  anon_uuid: string,
  no_cache?: boolean
) {
  const params = route_params;
  if (campaign_uuid) {
    if (current_route && current_route.campaign_uuid !== campaign_uuid) {
      reset_cache();
    }
    set_current_route(route, params, campaign_uuid, null);
    if (!no_cache && page_cache[route] && can_use_cache(route, params)) return true;
    return await send_message('gh:s:load_campaign', {});
  }
  if (anon_uuid) {
    if (current_route && current_route.anon_uuid !== anon_uuid) {
      reset_cache();
    }
    set_current_route(route, params, null, anon_uuid);
    if (!no_cache && page_cache[route] && can_use_cache(route, params)) return true;
    return await send_message('gh:s:load_anonymous', {});
  }
  log('page_load: no searchParam uuid');
  reset_cache();
  return false;
}

async function send_message(server_event: string, data: any): Promise<boolean> {
  try {
    if (!current_route) {
      log('send_message: no current_route');
      throw new Error('send_message: no current_route');
    }
    if (!network) {
      log('send_message: no network');
      throw new Error('send_message: no network');
    }
    log({ send_message: server_event });
    const body = {
      server_event,
      user,
      ...data,
      ...current_route,
      prev_ack,
      ghfs_v2: true,
    };
    if (prev_trip) {
      body.prev_trip = prev_trip;
      prev_trip = 0;
    }
    if (prefetch_route) body.route_id = prefetch_route;
    body.start_time = performance.now();
    const response = await network.send(server_event, body);
    const ack = Math.round(performance.now() - response.start_time);
    prev_ack = ack;
    return true;
  } catch (e: any) {
    prefetch_route = null;
    if (e?.error_message) {
      if (e?.error_message === 'Campaign not found.') {
        current_route = null;
        window.location.replace('/');
      }
      stores.set_alert(String(e.error_message).substring(0, 72), 0);
    } else if (e?.message !== 'Connecting...') {
      stores.set_alert(String(e).substring(0, 72), 0);
      if (process.env.NODE_ENV !== 'production') {
        log(String(e));
        console.log(e);
      }
    }
  }
  return false;
}

export function receive_page(data: any) {
  try {
    if (!data.page) {
      stores.set_alert('No data ' + transaction_id);
      log({ update: 'No data', transaction_id });
    } else {
      const trip = data.start_time ? Math.round(performance.now() - data.start_time) : 0;
      if (trip) prev_trip = trip;
      update_page(data.page, 'receive_page', trip);
      // If we were directed to a page or this is a response to our own message
      // then we received the correct data, otherwise reload our current page.
      // If it is cached nothing will happen, otherwise it will load the
      // correct data for the current route.
      if (data.route) cgoto(data.route);
      else if (data.broadcast) page_reload();
    }
    prefetch_route = null;
  } catch (e: any) {
    log(e);
    prefetch_route = null;
    if (e?.error_message === 'Campaign not found.') {
      stores.set_alert('Campaign not found.');
      current_route = null;
      window.location.replace('/');
    } else {
      const msg = e.error_message || e.toString();
      stores.set_alert('Error ' + msg.substring(0, 72));
    }
  }
}

export function set_initiative(info_id: number, initiative: string) {
  const data = { info_id, initiative };
  return send_message('gh:s:set_initiative', data);
}

export function force_next_round() {
  const data = { force_next_round: true };
  return send_message('gh:s:advance_state', data);
}

export function advance_state() {
  clear_selected_hex();
  return send_message('gh:s:advance_state', {});
}

export function open_door(id: number) {
  const data = { id };
  return send_message('gh:s:open_door', data);
}

export function move_figure(id: number, hex: Hex) {
  const data = { id, hex };
  return send_message('gh:s:move_figure', data);
}

export function auto_figure_turn() {
  return send_message('gh:s:auto_figure_turn', {});
}

export function perform_ability(figure_id: number, ability: Ability, hex?: Hex) {
  return send_message('gh:s:perform_ability', { figure_id, ability, hex });
}

export function set_element(element: string, state: ElementState) {
  const data = { element: element, state: state };
  return send_message('gh:s:set_element', data);
}

export function next_element(element: string) {
  const data = { element: element };
  return send_message('gh:s:next_element', data);
}

export function update_figure(
  group: FigureGroup,
  figure: FigureState,
  field: string,
  delta: any
) {
  const data = {
    figure_id: figure.id,
    object_code: group.object_code,
    info_id: group.info_id,
    player_id: group.player_id,
    field: field,
    delta: delta,
  };
  return send_message('gh:s:update_figure', data);
}

export function update_player_state(
  player_id: number,
  info_id: number,
  change: string,
  value: number
) {
  const data = { player_id, info_id, change, value };
  return send_message('gh:s:update_player_state', data);
}

export function update_player_state_token(
  player_id: number,
  info_id: number,
  token: Point
) {
  const data = { player_id, info_id, change: 'token', token };
  return send_message('gh:s:update_player_state', data);
}

export function draw_modifier(modifier_code: string, action?: string, value?: number) {
  const data = { modifier_code, action: action ?? 'draw', value: value };
  return send_message('gh:s:draw_modifier', data);
}

export function add_modifier(
  code: string,
  kind: ModifierKind,
  delta: number,
  position?: number
) {
  const data = {
    modifier_code: code,
    kind,
    delta,
    position,
  };
  return send_message('gh:s:add_modifier', data);
}

export function add_modifier_imitation(code: string, imitation_class_id: number) {
  const data = {
    modifier_code: code,
    imitation_class_id,
  };
  return send_message('gh:s:add_modifier', data);
}

export function monster_deck(monster_deck_id: number, action: string, value: number) {
  const data = { monster_deck_id, action, value };
  return send_message('gh:s:monster_deck', data);
}

export function condition_next(figure_id: number, condition_id: number, delta?: number) {
  const data = { figure_id, condition_id, delta };
  return send_message('gh:s:condition_next', data);
}

export function undo_state() {
  const data = { transaction_id };
  return send_message('gh:s:undo', data);
}

export function redo_state() {
  const data = { previous_transaction_id: transaction_id };
  return send_message('gh:s:redo', data);
}

export function remove_monster(
  info_kind: InfoKind,
  object_code: string,
  info_id: number,
  selected: string[]
) {
  const data = {
    info_kind,
    object_code,
    info_id,
    selected,
  };
  return send_message('gh:s:remove_monster', data);
}

export function summon_monster(
  info_kind: InfoKind,
  object_code: string,
  info_id: number,
  selected: string[],
  hex: Hex
) {
  const data = {
    info_kind,
    object_code,
    info_id,
    selected,
    hex,
  };
  return send_message('gh:s:summon_monster', data);
}

export function summon_monster_id(id: number, standee: string) {
  const data = { id: id, standee: standee };
  return send_message('gh:s:summon_monster_id', data);
}

export function character_summon(
  summoner_code: string,
  summoner_id: number,
  summon_code: string,
  summon_id: number,
  color: string,
  hex: Hex
) {
  const data = {
    summoner_code,
    summoner_id,
    summon_code,
    summon_id,
    color,
    hex,
  };
  return send_message('gh:s:character_summon', data);
}

export function edit_group(
  group: FigureGroup,
  field: string,
  delta: any,
  variant?: number
) {
  const data = {
    object_code: group.object_code,
    info_id: group.info_id,
    field,
    delta,
    variant,
  };
  return send_message('gh:s:edit_group', data);
}

export function group_stat_toggle(
  group: FigureGroup,
  kind: number,
  condition_id?: number
) {
  const data = {
    object_code: group.object_code,
    info_id: group.info_id,
    field: 'stat_toggle',
    kind,
    condition_id,
  };
  return send_message('gh:s:edit_group', data);
}

export function group_stat_delta(
  group: FigureGroup,
  kind: number,
  condition_id: number,
  variant: number,
  delta: number
) {
  const data = {
    object_code: group.object_code,
    info_id: group.info_id,
    field: 'stat_delta',
    kind,
    condition_id,
    variant,
    delta,
  };
  return send_message('gh:s:edit_group', data);
}

export function create_player(player: any) {
  return send_message('gh:s:create_player', player);
}

export function update_player(
  player_id: number,
  field: string,
  new_value: any,
  checked?: boolean
) {
  const data: any = {
    player_id,
    field,
    new_value,
    checked,
  };
  return send_message('gh:s:update_player', data);
}

export function retire_player(player_id: number, step_one?: boolean) {
  const data = { player_id: player_id, step_one };
  return send_message('gh:s:retire_player', data);
}

export function delete_player(player_id: number) {
  const data = { player_id: player_id };
  return send_message('gh:s:delete_player', data);
}

export function set_monster_groups(group_ids: number[]) {
  const data = { group_ids: group_ids };
  return send_message('gh:s:set_monster_groups', data);
}

export function add_overlay(info_id: number, q: number, r: number, context: string) {
  const data = { info_id, q, r, context };
  return send_message('gh:s:add_overlay', data);
}

export function update_overlay(overlay_id: number, field: string, value?: any) {
  const data = { overlay_id, field, value };
  return send_message('gh:s:update_overlay', data);
}

export function add_objective(stats: any) {
  const data = { stats: stats };
  return send_message('gh:s:add_objective', data);
}

export function add_char_summon(stats: any) {
  const data = { stats: stats };
  return send_message('gh:s:add_char_summon', data);
}

export function perform_action(action: Action, figure_id: number) {
  const data = { figure_id, action };
  return send_message('gh:s:perform_action', data);
}

export function ui_action(ui_button: string[], info_id?: number) {
  const data = { action: ui_button[2], info_id };
  return send_message('gh:s:ui_action', data);
}

export function create_campaign(app_uuid: string, game_id: number) {
  const data = { application_uuid: app_uuid, game_id };
  return send_message('gh:s:create_campaign', data);
}

export function load_campaign_uuid(uuid: string, context: string) {
  const data = { campaign_uuid: uuid, context: context };
  return send_message('gh:s:load_campaign', data);
}

export function end_scenario(scenario_id: number, success: boolean) {
  const data: any = { scenario_id, success, no_reset: true };
  return send_message('gh:s:end_scenario', data);
}

export function change_scenario(scenario_id: number, casual: boolean) {
  const data: any = {
    scenario_id,
    field: 'scenario_id',
    value: scenario_id,
    casual,
  };
  return send_message('gh:s:change_scenario', data);
}

export function restart_scenario() {
  const data: any = { field: 'restart' };
  return send_message('gh:s:change_setup', data);
}

export function replay_scenario() {
  return send_message('gh:s:change_setup', { field: 'replay' });
}

export function reset_scenario(scenario_id: number) {
  const data: any = {
    scenario_id,
    field: 'reset',
    value: scenario_id,
  };
  return send_message('gh:s:change_setup', data);
}

export function find_scenario(game_id: number, scenario_code: number, casual: boolean) {
  const data: any = { game_id, scenario_code, casual };
  return send_message('gh:s:find_scenario', data);
}

export function change_setup(field: string, value: any, player_id?: number) {
  const data: any = { field, value, player_id };
  return send_message('gh:s:change_setup', data);
}

export function journal_update(
  playing_state_uuid: string,
  scenario_id: number,
  field: string,
  delta: any,
  player_id?: number
) {
  const data: any = {
    playing_state_uuid,
    scenario_id,
    field,
    delta,
    player_id,
  };
  return send_message('gh:s:journal_update', data);
}

export function disable_special_rules(new_checked: boolean) {
  const data: any = { checked: new_checked };
  return send_message('gh:s:disable_special_rules', data);
}

export function journal_actions(actions: Action[], journal_id: number) {
  const data: any = {
    actions: actions,
    journal_id,
  };
  return send_message('gh:s:campaign_actions', data);
}

export function journal_action(
  journal_id: number,
  ...args: (string | number | boolean)[]
) {
  journal_actions([args.map((a: any) => String(a))], journal_id);
}

export function action(...args: (string | number | boolean)[]) {
  campaign_actions([args.map((a: any) => String(a))]);
}

export function campaign_actions(actions: Action[], kind?: JournalKind) {
  const data: any = {
    actions: actions,
    journal_kind: kind ?? JournalKind.GLOOMHAVEN,
  };
  return send_message('gh:s:campaign_actions', data);
}

export function load_map_edit(scenario_id: number) {
  const data = { scenario_id: scenario_id };
  return send_message('gh:s:load_map_edit', data);
}

export function map_transform(scenario_id: number, action: string, args: any) {
  const data = {
    scenario_id,
    action,
    args,
  };
  return send_message('gh:s:map_transform', data);
}

export function map_edit(item: MapItem, action: string, args: any) {
  const data = {
    scenario_id: item.scenario_id,
    id: item.id,
    action: action,
    args: args,
  };
  return send_message('gh:s:map_edit', data);
}

export function map_add_item(item: MapItem) {
  const data = {
    scenario_id: item.scenario_id,
    item: item,
  };
  return send_message('gh:s:map_add_item', data);
}

export function map_delete_item(item: MapItem) {
  const data = {
    scenario_id: item.scenario_id,
    item: item,
  };
  return send_message('gh:s:map_delete_item', data);
}

export function delete_campaign(application_uuid: string, campaign_uuid: string) {
  const data = {
    application_uuid,
    campaign_uuid,
  };
  return send_message('gh:s:delete_campaign', data);
}

export function delete_anonymous(anon_uuid: string) {
  const data = { anon_uuid };
  return send_message('gh:s:delete_anonymous', data);
}

export function load_application(uuid: string, context: string) {
  const data = { application_uuid: uuid, context: context };
  return send_message('gh:s:load_application', data);
}

export function change_application(uuid: string, field: string, value: any) {
  const data: any = {
    application_uuid: uuid,
    field: field,
    value: value,
  };
  return send_message('gh:s:change_application', data);
}

export function feedback(feedback: string) {
  const uuid = current_route?.campaign_uuid ?? current_route?.anon_uuid ?? '';
  return send_message('gh:s:feedback', { uuid, feedback });
}

export function update_feedback(feedback_id: number, feedback_status: number) {
  const uuid = current_route?.campaign_uuid ?? current_route?.anon_uuid ?? '';
  return send_message('gh:s:feedback', { uuid, feedback_id, feedback_status });
}

export function latest_image_infos(client_image_version: number) {
  return send_message('gh:s:latest_infos', { client_image_version });
}

export function load_info_editor(uuid: string) {
  const data = {
    application_uuid: uuid,
  };
  if (data.application_uuid) send_message('gh:s:load_info_editor', data);
}

export function info_read(kind: InfoKind, game_id: number, info_id: number) {
  const data = {
    info_kind: kind,
    game_id: game_id,
    info_id: info_id,
  };
  return send_message('gh:s:info_read', data);
}

export function info_import(infos: any[], auto_cap: boolean) {
  return send_message('gh:s:info_import', { infos, auto_cap });
}

export function info_add(kind: InfoKind, game_id: number, info?: any) {
  const data = {
    info_kind: kind,
    game_id: game_id,
    info,
  };
  return send_message('gh:s:info_add', data);
}

export function info_edit(info_id: number) {
  return send_message('gh:s:info_edit', { info_id: info_id });
}

export function info_delete(kind: InfoKind, game_id: number, record_id: number) {
  const data = {
    info_kind: kind,
    game_id: game_id,
    record_id: record_id,
  };
  return send_message('gh:s:info_delete', data);
}

export function info_save(record: InfoRecord) {
  return send_message('gh:s:info_save', { record: record });
}

export function info_merge(record_id: number, merge: any) {
  return send_message('gh:s:info_save', { record_id, merge });
}

export function update_latest_infos() {
  return send_message('gh:s:update_latest_infos', {});
}

export function create_anonymous(game_id: number, name: string) {
  const data = { game_id, name };
  return send_message('gh:s:create_anonymous', data);
}

export function load_anonymous(anon_uuid: string) {
  const data = { anon_uuid };
  return send_message('gh:s:load_anonymous', data);
}

export function draw_loot_card(group_info_id: number) {
  return send_message('gh:s:loot_deck', { action: 'draw', group_info_id });
}

export function loot_deck_change(loot_kind: number, delta: number) {
  return send_message('gh:s:loot_deck', { action: 'change', loot_kind, delta });
}

export function read_scenario_section(section_index: number) {
  return send_message('gh:s:read_scenario_section', { section_index });
}

export function reveal_page_label(page_label: string, detail_id?: number) {
  return send_message('gh:s:reveal_page_label', { page_label, detail_id });
}

export function update_user_info(info_id: number, info: any) {
  return send_message('gh:s:user_info', { info_id, info });
}

export function import_blob(blob: string) {
  return send_message('gh:s:import_blob', { blob });
}

export function reset_map_connections() {
  return send_message('gh:s:reset_map_connections', {});
}

export function log_exception(message: any) {
  return send_message('gh:s:log_exception', message);
}

export function log_message(message: any) {
  return send_message('gh:s:log_message', message);
}

export function test_notification() {
  return send_message('gh:s:test_notification', {});
}

export function reset_notifications() {
  return send_message('gh:s:reset_notifications', {});
}

export function update_user_name(user_name: string) {
  return send_message('gh:s:update_client', { update: 'user_name', user_name });
}

export function update_settings(settings: any) {
  if (settings)
    return send_message('gh:s:update_client', { update: 'settings', settings });
}

export function update_notifications(notifications: any) {
  if (notifications)
    return send_message('gh:s:update_client', { update: 'notifications', notifications });
}

export function update_subscription(subscription: any) {
  if (subscription)
    return send_message('gh:s:update_client', { update: 'subscription', subscription });
}

export function update_endpoint(endpoint: string) {
  return send_message('gh:s:update_client', { update: 'endpoint', endpoint });
}

export async function retry_fn(fn: any, retries = 2, interval = 1000): Promise<any> {
  try {
    return await fn();
  } catch (error: any) {
    console.log(error);
    if (retries) {
      log_exception({ retries, message: error.toString() });
      await wait(interval);
      return retry_fn(fn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function group_init(group: FigureGroup, setting: boolean, approx: boolean) {
  if (is_character(group)) {
    if ((group.initiative ?? 0) <= 0)
      return { initiative: '--', init: '', approx: false };
    if (group.long_rest) return { initiative: 'Rest', init: 'r', approx: true };
    if (setting && !is_client_id(group.socket_id))
      return !approx
        ? { initiative: '??', init: '?', approx: false }
        : group.initiative < 34
        ? { initiative: 'Fast', init: 'f', approx: true }
        : group.initiative < 67
        ? { initiative: 'Med.', init: 'm', approx: true }
        : { initiative: 'Slow', init: 's', approx: true };
  } else if ((group.initiative ?? 0) <= 0 || group.initiative > 99)
    return { initiative: '', init: '', approx: false };
  const init = String(group.initiative);
  return { initiative: init, init, approx: false };
}
