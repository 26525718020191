import App from './App.svelte';
import './index.css';

const app = new App({ target: document.body });

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('service-worker.js')
      .then((registration) => registration.update())
      .catch((error) => console.log('service worker register error', error));
  });
}

export default app;
