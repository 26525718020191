<script>
  import { push } from 'svelte-spa-router';
</script>

<h2>GHFS: Campaign limit</h2>

<div class="list pad-1">
  <p>There is a limit to the number of anonymous campaigns created by a client.</p>
  <p>They are also throttled to 1 new campaign per 5 minutes per client.</p>
  <button class="default" on:click={() => push('/')}>Home</button>
</div>
