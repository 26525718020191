<script lang="ts">
  import Router from 'svelte-spa-router';
  import CampaignLimitPage from 'routes/CampaignLimitPage.svelte';
  import { wrap } from 'svelte-spa-router/wrap';
  import { load_account } from 'v2/account';
  import { cerror, log_exception, retry_fn } from 'v2/send';
  import { onMount } from 'svelte';
  import { prefix } from 'common/config';

  const routes = {
    '/campaign-limit': CampaignLimitPage,
    '/account': wrap({
      asyncComponent: () => retry_fn(() => import('../routes/Account.svelte')),
      conditions: [load_account],
    }),
    '/': wrap({ asyncComponent: () => retry_fn(() => import('../routes/Home.svelte')) }),
    '/c/*': wrap({
      asyncComponent: () => retry_fn(() => import('../routes/Connect.svelte')),
    }),
  };

  onMount(() => {
    if (prefix !== 'dev_')
      window.onunhandledrejection = (event) => {
        event.preventDefault();
        event.stopPropagation();
        try {
          const message = {
            reason: event.reason.message,
            file: event.reason.fileName,
            line: event.reason.lineNumber,
            column: event.reason.columnNumber,
            stack: event.reason.stack,
          };
          log_exception(message);
          cerror('/c/exception', message);
        } catch (e) {
          // An exception in our exception handler. oops.
          console.log(event.reason);
          console.log(e);
          window.location.replace('/');
        }
        return false;
      };
  });
</script>

<Router {routes} />
